import React, { useEffect } from "react";
import "../App.css";
import { Copy, Discord, QRCode, Telegram, X, XClose } from "../Assets";

export default function Popup({ show, onClose }) {
  if (!show) {
    return null;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const popupContent = document.getElementById("popup-content");
      if (popupContent && !popupContent.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const copyToClipboard = () => {
    const input = document.getElementById("copyInput");
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  return (
    <div className="p-20 z-50 mt-20 ">
      <div className="rounded-lg px-10" id="popup-overlay">
        <div className="rounded-lg lg:py-5 lg:px-[20px] px-[25px] py-[20px] lg:w-[35%] w-auto h-auto" id="popup-content">
          <div className="flex justify-end mb-3" id="close-btn">
            <a onClick={onClose}><img src={XClose} alt="closebtn" /></a>
          </div>
          <div className="flex flex-col gap-5 justify-between items-center">
            <h1 className="uppercase lg:text-xl text-sm text-goldShade text-center meregular__font">PRESALE NOT LIVE YET!</h1>
            <h2 className="lg:text-[15px] text-[12px] uppercase text-white text-design meregular__font text-center">Kindly Join our community below to be notified when it starts</h2>
            <div className="flex items-center gap-5 mt-5">
              <a href="https://t.me/pandianaonsol">
                <img src={Telegram} alt="Telegram" className="w-10" />
              </a>
              <a href="https://discord.gg/pandiana">
                <img src={Discord} alt="Discord" className="w-10" />
              </a>
              <a href="https://x.com/pandianaonsol">
                <img src={X} alt="X" className="w-10" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
