import React, { useState, useEffect } from "react";
import { BgA, Discord, Link, PandaA, Telegram, Whitepaper, X } from "../Assets";
import "../App.css";
import { BuyPopup, Popup, Timer } from "../Components";

export default function Hero() {
  const [showPopup, setShowPopup] = useState(false);
  const [showBuyPopup, setShowBuyPopup] = useState(false);
  const [solValue, setSolValue] = useState("");
  const [pndaValue, setPndaValue] = useState(400);
  // const [solRaised, setSolRaised] = useState(0);
  // const [salePercentage, setSalePercentage] = useState(0);

  const handlePopup = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleBuyPopup = (e) => {
    e.preventDefault();
    setShowBuyPopup(true);
  };

  const closeBuyPopup = () => {
    setShowBuyPopup(false);
  };

  const handleInputChange = (e) => {
    let value = parseInt(e.target.value);

    if (isNaN(value)) {
      setSolValue("");
      setPndaValue(400);
    } else if (value > 100) {
      value = 100;
      setSolValue(100);
      setPndaValue(100 * 400);
    } else {
      setSolValue(value);
      setPndaValue(value * 400);
    }
  };

  // const fetchSolRaised = () => {
  //   axios.get('https://api.penguiana.com/api/balance')
  //     .then((res) => {
  //       setSolRaised(res.data);
  //       const percentage = (res.data * 100) / 3000;
  //       setSalePercentage(percentage);
  //     })
  //     .catch((error) => {
  //       console.log('Error fetching data:', error);
  //     });
  // };

  // useEffect(() => {
  //   const interval = setInterval(fetchSolRaised, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div>
      <div className="" id="buypnda">
        <div className="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-7xl md:px-24 lg:px-40 lg:py-0">
          <div className="flex flex-col items-center justify-between xl:flex-row">
            <div className="w-full max-w-xl mb-14 xl:mb-0 xl:pr-16 xl:w-7/12">
              <div className="lg:flex lg:justify-start justify-center w-full">
                <img
                  src={PandaA}
                  alt="Panda"
                  className="mb-5 lg:w-60 w-40 lg:m-0 m-auto"
                />
              </div>
              <h2 className="max-w-lg mb-6 font-bold text-[30px] lg:text-[45px] tracking-tight text-white sm:leading-none meregular__font uppercase lg:text-start text-center mt-5">
                Channel Your <br /> Inner Panda
              </h2>
              <p className="max-w-xl mb-4 text-gray-300 lg:text-[15px] text-[15px] neugro__font lg:text-start text-center">
                Welcome to Pandiana, the next big wave in the world of
                play-to-earn gaming. We're not just another memecoin but a
                revolution in the blockchain gaming arena, built on Solana for
                unmatched speed and efficiency.
              </p>
              <p className="items-center font-semibold tracking-wider transition-colors duration-200 text-white meregular__font leading-[24px] lg:text-start text-center">
                Join Us On
              </p>
              <div className="lg:flex grid grid-cols-1 lg:justify-normal justify-center items-center gap-5 mt-5">
                <div className="flex lg:justify-normal justify-center items-center gap-5">
                  <a
                    href="https://t.me/pandianaonsol"
                    target="blank"
                    className="cursor-pointer hover:opacity-90"
                  >
                    <img src={Telegram} alt="Telegram" className="w-10" />
                  </a>
                  <a
                    href="https://discord.gg/pandiana"
                    target="blank"
                    className="cursor-pointer hover:opacity-90"
                  >
                    <img src={Discord} alt="Discord" className="w-10" />
                  </a>
                  <a
                    href="https://x.com/pandianaonsol"
                    target="blank"
                    className="cursor-pointer hover:opacity-90"
                  >
                    <img src={X} alt="X" className="w-10" />
                  </a>
                </div>
                <div className="lg:m-0 m-auto lg:py-0 py-3">
                  <a
                    href="https://docs.pandiana.io"
                    className="flex items-center gap-4 rounded-full cursor-pointer hover:opacity-90"
                    target="blank"
                  >
                    {/* <p className="text-white meregular__font text-xs">
                    Whitepaper
                  </p> */}
                    <img src={Whitepaper} alt="Link" className="w-28" />
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full max-w-xl xl:w-5/12">
              <div className="blurBGG rounded bx-shadow lg:p-6 p-2 flex flex-col gap-4">
                <div className="flex flex-col items-center justify-center">
                  <h3 className="font-semibold sm:text-center lg:px-2 lg:py-2 px-3 py-5 text-[10px] lg:text-[20px] primaryColorText meregular__font">
                    $PNDA TOKEN PRESALE
                  </h3>
                  <p className="text-white neugro__font lg:text-sm text-sm">
                    Starts in
                  </p>
                </div>
                <div>
                  <Timer />
                </div>
                <div className="flex justify-center gap-14 items-center">
                  <div className="flex flex-col gap-1 justify-center items-center">
                    <p className="lg:text-[15px] text-[7px] text-design neugro__font">
                      Presale Price
                    </p>
                    <h1 className="lg:text-[11px] text-[8px] text-design font-bold meregular__font">
                      1 SOL = 400 $PNDA
                    </h1>
                  </div>
                  <div className="flex flex-col gap-1 justify-center items-center">
                    <p className="lg:text-[15px] text-[7px] text-design neugro__font">
                      Listing Price
                    </p>
                    <h1 className="lg:text-[11px] text-[8px] text-design font-bold meregular__font">
                      1 SOL = 200 $PNDA
                    </h1>
                  </div>
                </div>
                <p className="lg:text-[18px] text-[12px] text-design font-semibold text-center neugro__font">
                  Listing at 50% on Raydium
                </p>
                <form>
                  <div className="mb-1 sm:mb-2 relative rounded-full flex justify-center items-center w-full bg-darkColorC border-2 border-secondaryColor lg:h-10 h-8 p-2 backdropp overflow-hidden">
                    {/* <input
                      className="absolute left-0 bg-goldShade w-[90%] lg:h-7 h-5 inset-0 rounded-full mt-1 mx-[0.3rem] m-auto text-center font-bold text-gray-500"
                      value={"10,000 SOL Raised"}
                      style={{ width: `${solRaised}%`, maxWidth: '97%' }}
                    />
                    <p className="text-design lg:text-[15px] text-[12px] font-bold neugro__font">
                      {salePercentage} SOL
                    </p> */}
                    <input className="absolute left-0 bg-transparent border border-goldShade w-[97%] lg:h-7 h-5 inset-0 rounded-full mt-1 mx-[0.3rem] m-auto text-center font-bold" />
                    <input
                      className="absolute left-0 bg-goldShade w-[0%] lg:h-7 h-5 inset-0 rounded-full mt-1 mx-[0.3rem] m-auto text-center font-bold"
                      // value={"10,000 SOL Raised"}
                      // style={{ width: `${progressBarWidth}%`, maxWidth: '97%' }}
                    />
                    <p className="absolute top-0 mt-[5px] lg:text-[15px] text-[12px] font-extrabold neugro__font text-design">
                      0 SOL
                    </p>
                  </div>
                  <div className="mb-1 sm:mb-2 flex justify-between items-center gap-10 lg:px-0 px-1">
                    <div>
                      <label className="text-design lg:text-[15px] text-[12px]  font-bold meregular__font lg:px-0 px-1">
                        SOL
                      </label>
                      <input
                        placeholder="1"
                        required
                        type="number"
                        value={solValue}
                        onChange={handleInputChange}
                        max="100"
                        className="flex-grow w-full h-[30px] lg:h-12 px-4 mb-2 transition duration-200 bg-darkColorC border border-secondaryColor rounded-[10px] shadow-sm appearance-none focus:border-secondaryColor focus:outline-none focus:shadow-outline placeholder:text-design placeholder:font-bold lg:placeholder:text-xl placeholder:text-[12px] placeholder:meregular__font text-white"
                      />
                    </div>
                    <div>
                      <label className="text-design lg:text-[15px] text-[12px]  font-bold meregular__font">
                        $PNDA
                      </label>
                      <input
                        placeholder="400"
                        required
                        type="number"
                        value={pndaValue}
                        className="flex-grow w-full h-[30px] lg:h-12 px-4 mb-2 transition duration-200 bg-darkColorC border border-secondaryColor rounded-[10px] shadow-sm appearance-none focus:border-secondaryColor focus:outline-none focus:shadow-outline placeholder:text-design placeholder:font-bold lg:placeholder:text-xl placeholder:text-[12px] placeholder:meregular__font text-gray-300"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="mt-5 mb-3 sm:mb- flex justify-center items-center">
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center w-40 h-10 px-6 font-medium tracking-wide text-gray-300 transition duration-200 rounded-xl shadow-md btnCta hover:opacity-90 text-[10px] meregular__font"
                      onClick={handleBuyPopup}
                    >
                      BUY $PNDA
                    </button>
                  </div>
                  <div className="flex justify-center gap-10 items-center lg:px-0 px-2">
                    <div className="flex flex-col gap-2 justify-center items-center">
                      <p className="lg:text-[15px] text-[7px] text-design neugro__font">
                        Presale Allocation
                      </p>
                      <h1 className="lg:text-[13px] text-[8px]  font-bold flex justify-center items-center gap-2 m-auto">
                        <span className="flex justify-center items-center gap-0">
                          <span className="text-design meregular__font">(</span>
                          <span className="text-goldShade meregular__font">
                            $PNDA
                          </span>{" "}
                        </span>
                        <span className="text-design meregular__font">
                          4,000,000)
                        </span>
                      </h1>
                    </div>
                    <div className="flex flex-col gap-2 justify-center items-center">
                      <p className="text-design lg:text-[15px] text-[7px] neugro__font">
                        Total Supply
                      </p>
                      <h1 className="lg:text-[13px] text-[8px] font-bold flex justify-center items-center gap-2 m-auto">
                        <span className="flex justify-center items-center gap-0">
                          <span className="text-design meregular__font">(</span>
                          <span className="text-goldShade meregular__font">
                            $PNDA
                          </span>{" "}
                        </span>
                        <span className="text-design meregular__font">
                          10,000,000)
                        </span>
                      </h1>
                    </div>
                  </div>
                </form>
                <a
                  href="https://docs.pandiana.io/buy-pnda-token"
                  className="font-bold lg:text-sm text-[10px] text-goldShade text-center neugro__font mb-4"
                  target="_blank"
                  // onClick={handlePopup}
                >
                  How To Buy
                </a>
              </div>
              <Popup show={showPopup} onClose={closePopup} />
              <BuyPopup show={showBuyPopup} onClose={closeBuyPopup} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
