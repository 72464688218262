import React, { useState, useEffect, useRef } from "react";
import "./Carousel.css";
import { Left, Right } from "../Assets";
import Popup from "./Popup";
import { BuyPopup } from ".";

export default function Carousel({ images }) {
  const [currentIndex, setCurrentIndex] = useState(3);
  const carouselRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showBuyPopup, setShowBuyPopup] = useState(false);

  const handlePopup = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleBuyPopup = (e) => {
    e.preventDefault();
    setShowBuyPopup(true);
  };

  const closeBuyPopup = () => {
    setShowBuyPopup(false);
  };

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentIndex === 0) {
        setCurrentIndex(images.length);
      } else if (currentIndex === images.length + 1) {
        setCurrentIndex(1);
      }
    };

    const carousel = carouselRef.current;
    carousel.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      carousel.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [currentIndex, images.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const getTransformStyle = () => {
    return `translateX(calc(50% - ${
      currentIndex * (90 / (images.length + 2))
    }% - ${currentIndex * 50}px))`;
  };

  return (
    <main>
      <div className="carousel-container">
        <div className="carousel-wrapper">
          <div
            className="carousel"
            ref={carouselRef}
            style={{
              transform: getTransformStyle(),
              transition: "transform 0.5s ease",
            }}
          >
            <div className="carousel-item">
              <img
                src={images[images.length - 1].src}
                className="carousel-image"
                alt={images[images.length - 1].alt}
              />
              <p className="carousel-caption">
                {images[images.length - 1].caption}
              </p>
            </div>
            {images.map((image, index) => (
              <div
                key={index}
                className={`carousel-item ${
                  index === currentIndex - 1 ? "center" : ""
                }`}
              >
                <img
                  src={image.src}
                  className="carousel-image"
                  alt={image.alt}
                />
                <p className="carousel-caption">{image.caption}</p>
              </div>
            ))}
            <div className="carousel-item">
              <img
                src={images[0].src}
                className="carousel-image"
                alt={images[0].alt}
              />
              <p className="carousel-caption">{images[0].caption}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center mt-3">
        <button className="bg-primaryColor w-40 lg:p-3 p-2 mt-5 rounded-xl hover:opacity-90" onClick={handleBuyPopup}>
          <a
            className="text-center meregular__font text-design lg:text-[10px] text-[8px]"
          >
            Buy $PNDA
          </a>
        </button>
      </div>

      <div className="flex justify-center items-center mt-10 gap-20 mb-32">
        <a className="cursor-pointer hover:opacity-90" onClick={handlePrev}>
          <img src={Left} alt="Left" className="lg:w-12 w-10" />
        </a>
        <a className="cursor-pointer hover:opacity-90" onClick={handleNext}>
          <img src={Right} alt="Right" className="lg:w-12 w-10" />
        </a>
      </div>
      {/* <Popup show={showPopup} onClose={closePopup} /> */}
      <BuyPopup show={showBuyPopup} onClose={closeBuyPopup} />
    </main>
  );
}
