import React, { useEffect } from "react";
import "../App.css";
import { Copy, Discord, QRCode, Telegram, X, XClose } from "../Assets";

export default function Popup({ show, onClose }) {
  if (!show) {
    return null;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const popupContent = document.getElementById("popup-content");
      if (popupContent && !popupContent.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const copyToClipboard = () => {
    const input = document.getElementById("copyInput");
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  return (
    <div className="p-20 z-50 mt-20 ">
      <div className=" rounded-lg px-10" id="popup-overlay">
        <div className=" rounded-lg lg:py-5 lg:px-[20px] px-[25px] py-[20px] lg:w-[35%] w-auto h-auto" id="popup-content">
          <div className="flex justify-end" id="close-btn">
            <a onClick={onClose}><img src={XClose} alt="closebtn" /></a>
          </div>
          <div className="flex flex-col gap-5 justify-between items-center">
            <h1 className="uppercase text-sm text-goldShade text-center meregular__font">Buy $pnda token</h1>
            <p className="text-design text-center lg:w-[400px] w-[280px] neugro__font text-xs">
              Send SOL to the address below to participate in the presale, you can also scan the QR code
            </p>
            <img src={QRCode} alt="qrcode" className="w-20"/>
            <div className="flex justify-between items-center bg-darkColor p-2 rounded-full">
              <input id="copyInput" value="https://qrco.de/bfAKFr" readOnly className="outline-none border-collapse bg-darkColor text-white meregular__font text-xs"/>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  copyToClipboard();
                }}
              >
                <img src={Copy} alt="copy" className="w-5"/>
              </a>
            </div>
            <h1 className="text-sm uppercase text-design meregular__font">How to buy</h1>
            <ul className="text-white list-decimal text-justify flex flex-col gap-1 neugro__font text-xs lg:w-[400px] w-[280px]">
              <li>Set up Solana wallet (Trust Wallet, Solflare, Phantom will work)</li>
              <li>Buy Solana from Binanace, Bybit, Coinbase</li>
              <li>Send the bought Solana to your personal wallet</li>
              <li>Send the Solana from your personal (Not exchanged) wallet to $SPNDA Pre-sale address</li>
              <li>Visit the leadership board and input your address to see the amount of $PNDA you will recieve</li>
              <li>Once Pre-sale ends, your token will be airdropped into your wallet</li>
            </ul>
            <h2 className="text-[10px] uppercase text-white text-design meregular__font text-center">Join our community if you have any enquires</h2>
            <div className="flex items-center gap-5 mt-5">
              <a href="https://t.me/pandianaonsol">
                <img src={Telegram} alt="Telegram" className="w-6" />
              </a>
              <a href="https://discord.gg/pandiana">
                <img src={Discord} alt="Discord" className="w-6" />
              </a>
              <a href="https://x.com/pandianaonsol">
                <img src={X} alt="X" className="w-6" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
