import React, { useState } from "react";
import { Left, NFTA, NFTB, NFTC, PandaA, PandaB, Right, Star } from "../Assets";
import Carousel from "../Components/Carousel";
import "../App.css";
import { BuyPopup, Popup } from "../Components";

export default function Body() {
  // const [showPopup, setShowPopup] = useState(false);
  const [showBuyPopup, setShowBuyPopup] = useState(false);

  // const handlePopup = (e) => {
  //   e.preventDefault();
  //   setShowPopup(true);
  // };

  // const closePopup = () => {
  //   setShowPopup(false);
  // };
  const handleBuyPopup = (e) => {
    e.preventDefault();
    setShowBuyPopup(true);
  };

  const closeBuyPopup = () => {
    setShowBuyPopup(false);
  };

  const images = [
    { src: NFTB, alt: "Panda Max", caption: "Panda Max" },
    { src: NFTB, alt: "Panda Max", caption: "Panda Max" },
    { src: NFTC, alt: "Panda Max", caption: "Panda Max" },
    { src: NFTA, alt: "Panda Max", caption: "Panda Max" },
    { src: NFTA, alt: "Panda Max", caption: "Panda Max" },
  ];

  return (
    <div>
      {/* Section 1 */}
      <section className="" id="bgimgB">
        <div className="max-w-xl mb-10 md:mx-auto text-center lg:max-w-2xl md:mb-12 flex flex-col justify-center items-center gap-3">
          <h2 className="max-w-lg font-sans lg:text-[20px] text-[15px] font-bold leading-none tracking-tight text-design neugro__font px-3">
            Unlocking game levels, and accessing exclusive in-game content.
          </h2>
          <p className="lg:text-[28px] text-[16px] font-bold text-white meregular__font uppercase px-3">
            These tokens are your gateway to minting playable characters.
          </p>
        </div>
        <div className="">
          <Carousel images={images} />
        </div>
      </section>

      {/* Section - 2 */}
      <section className="h-auto lg:p-3 p-3" id="bgimgC">
        <div className="mb-16">
          <div className="pt-20">
            <div className="blurBGG px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg lg:px-2 lg:py-14">
              <div className="max-w-xl mb-10 md:mx-auto text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 font-sans lg:text-xl leading-none tracking-tight text-design neugro__font text-[15px] md:mx-auto">
                  Start Your Pandiana Expedition
                </h2>
                <p className="text-bold text-white meregular__font lg:text-xl text-[15px] uppercase">
                  Embark on a bamboo filled journey by acquiring at least
                </p>
              </div>
              <div className="flex items-center justify-center" onClick={handleBuyPopup}>
                <button
                  type="submit"
                  className="flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded-lg shadow-md bg-primaryColor focus:shadow-outline focus:outline-none"
                >
                  <p className="text-design meregular__font lg:text-sm text-xs">
                    5000 $PNDA Tokens
                  </p>
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <img src={PandaB} alt="Panda" className="w-auto h-auto" />
          </div>
        </div>
        {/* <Popup show={showPopup} onClose={closePopup} /> */}
        <BuyPopup show={showBuyPopup} onClose={closeBuyPopup} />
      </section>

      {/* Section - 3 */}
      <section className="h-auto" id="pandanomics">
        <div className="pt-20">
          <h1 className="lg:text-2xl text-sm text-goldShade font-bold text-center meregular__font">
            PANDANOMICS
          </h1>
          <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-14">
            <div className="flex flex-col gap-5">
              <div className="relative lg:flex grid grid-cols-1 gap-5 lg:grid-cols-2 justify-center items-center">
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 lg:w-96 w-90 h-40">
                  <div className="p-5 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 font-bold neugro__font text-design lg:text-xl text-sm">
                      Total Supply
                    </p>
                    <p className="lg:text-xl text-sm leading-5 text-center meregular__font flex gap-2">
                      <span className="text-goldShade font-bold">$PNDA </span>
                      <p className="text-design">10,000,000</p>
                    </p>
                  </div>
                  <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                </div>
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 w-90 h-40">
                  <div className="p-5 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 font-bold neugro__font text-design lg:text-xl text-sm">
                      Presale Allocation
                    </p>
                    <p className="lg:text-xl text-sm leading-5 text-center meregular__font flex gap-2">
                      <p className="flex gap-0">
                        <p className="text-design">30% (</p>
                        <span className="text-goldShade font-bold">$PNDA </span>
                      </p>
                      <p className="text-design">4,000,000)</p>
                    </p>
                  </div>
                  <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                </div>
              </div>
              <div className="relative lg:flex grid grid-cols-1 gap-5 lg:grid-cols-2 justify-center items-center">
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 w-90 h-40">
                  <div className="p-5 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 font-bold neugro__font text-design lg:text-xl text-sm">
                      Liquidity & Exchange Listings
                    </p>
                    <p className="lg:text-xl text-sm leading-5 text-center meregular__font flex gap-2">
                      <p className="flex gap-0">
                        <p className="text-design">10% (</p>
                        <span className="text-goldShade font-bold">$PNDA </span>
                      </p>
                      <p className="text-design">1,000,000)</p>
                    </p>
                  </div>
                  <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                </div>
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 w-90 h-40">
                  <div className="p-5 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 font-bold neugro__font text-design lg:text-xl text-sm">
                      Airdrop & Marketing
                    </p>
                    <p className="lg:text-xl text-sm leading-5 text-center meregular__font flex gap-2">
                      <p className="flex gap-0">
                        <p className="text-design">20% (</p>
                        <span className="text-goldShade font-bold">$PNDA </span>
                      </p>
                      <p className="text-design">3,000,000)</p>
                    </p>
                  </div>
                  <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                </div>
              </div>
              <div className="relative lg:flex grid grid-cols-1 gap-5 justify-center items-center">
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 w-90 h-40">
                  <div className="p-5 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 font-bold neugro__font text-design lg:text-xl text-sm">
                      Team
                    </p>
                    <p className="lg:text-xl text-sm leading-5 text-center meregular__font flex gap-2">
                      <p className="flex gap-0">
                        <p className="text-design">80% (</p>
                        <span className="text-goldShade font-bold">$PNDA </span>
                      </p>
                      <p className="text-design">1,000,000)</p>
                    </p>
                  </div>
                  <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                </div>
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 w-90 h-40">
                  <div className="p-5 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 font-bold neugro__font text-design lg:text-xl text-sm">
                      Staking & In-Game Rewards
                    </p>
                    <p className="lg:text-xl text-sm leading-5 text-center meregular__font flex gap-2">
                      <p className="flex gap-0">
                        <p className="text-design">32% (</p>
                        <span className="text-goldShade font-bold">$PNDA </span>
                      </p>
                      <p className="text-design">1,000,000)</p>
                    </p>
                  </div>
                  <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                </div>
              </div>
              <div className="flex justify-center items-center w-full">
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 lg:w-96 w-full h-40">
                  <div className="p-5 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 font-bold neugro__font text-design">
                      Exchange & Development
                    </p>
                    <p className="lg:text-xl text-sm leading-5 text-center meregular__font flex gap-2">
                      <p className="flex gap-0">
                        <p className="text-design">10% (</p>
                        <span className="text-goldShade font-bold">$PNDA </span>
                      </p>
                      <p className="text-design">500,000)</p>
                    </p>
                  </div>
                  <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section - 4 */}
      <section className="h-auto" id="bgimgE">
        <div className="pt-20">
          <h1 className="lg:text-2xl text-sm mb-2 text-white font-bold text-center uppercase meregular__font">
            PANDIANA Roadmap
          </h1>
          <p className="capitalize text-white neugro__font text-center text-sm">
            Our Bamboo Journey to the top
          </p>
          <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-14">
            <div className="flex flex-col gap-5">
              <div className="relative lg:flex grid grid-cols-1 gap-5 lg:grid-cols-2 justify-center items-center">
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 lg:w-96 w-90 h-40">
                  <div className="p-2 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 meregular__font text-goldShade lg:text-[15px] text-[10px] uppercase">
                      Pandception
                    </p>
                    <p className="lg:text-[15px] text-[10px] leading-5 text-center meregular__font flex gap-2 text-white uppercase">
                      The genesis of $PNDA tokens, minted on the Solana
                      blockchain
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 lg:w-96 w-90h-40">
                  <div className="p-2 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 meregular__font text-goldShade lg:text-[15px] text-[10px] uppercase">
                      Pandacubation
                    </p>
                    <p className="lg:text-[15px] text-[10px] leading-5 text-center meregular__font flex gap-2 text-white uppercase">
                      Strengthening our roots and prepping for the first wave of
                      players
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative lg:flex grid grid-cols-1 gap-5 lg:grid-cols-2 justify-center items-center">
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 lg:w-96 w-90 h-40">
                  <div className="p-2 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 meregular__font text-goldShade lg:text-[15px] text-[10px] uppercase">
                      The Great Feast
                    </p>
                    <p className="lg:text-[15px] text-[10px] leading-5 text-center meregular__font flex gap-2 text-white uppercase">
                      A pivotal moment where gameplay deepens and rewards
                      multiply
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 blurBGG group hover:shadow-2xl py-5 lg:w-96 w-90 h-40">
                  <div className="p-2 flex justify-center items-center flex-col m-auto">
                    <p className="mb-4 meregular__font text-goldShade lg:text-[15px] text-[10px] uppercase">
                      Sky
                    </p>
                    <p className="lg:text-[15px] text-[10px] uppercase leading-5 text-center meregular__font flex gap-2 text-white">
                      Reaching our ultimate goals and setting new benchmarks in
                      play-to-earn gaming
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
